<template>
	<v-sheet
		class="customer"
		style="height: calc(100vh - 88px)"
		v-if="getPermission('customer::view')"
	>
		<v-row>
			<v-col md="4" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					disabled
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.customer_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="8" class="py-1 my-auto text-right listing-right">
				<v-btn
					v-if="getPermission('customer::create')"
					depressed
					:disabled="pageLoading"
					tile
					color="blue darken-4"
					class="text-white"
					:to="{
						name: 'customer-create',
						query: { tab: 'overview' },
					}"
				>
					<v-icon left> mdi-plus </v-icon>
					Create
				</v-btn>
				<v-menu
					bottom
					left
					origin="center center"
					transition="slide-y-transition"
					rounded="0"
					offset-y
					max-height="400px"
					:close-on-content-click="false"
					content-class="white-background"
					v-if="getPermission('setting::edit')"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							color="blue darken-4"
							class="text-white"
							tile
							:disabled="pageLoading"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon>mdi-table-edit</v-icon>
						</v-btn>
					</template>
					<Draggable
						tag="ul"
						v-model="draggableThead"
						class="draggable-group"
						handle=".draggable-drag-icon"
						v-on:change="updateTable('customer')"
					>
						<template v-for="cols in draggableThead">
							<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
								<v-checkbox
									dense
									v-model="draggableTheadShow"
									v-bind:value="cols.key"
									:label="cols.name"
									:disabled="cols.fixed || pageLoading"
									color="blue"
									hide-details
									class="mt-0 mb-0"
									v-on:change="updateTableVisiblity('customer')"
								></v-checkbox>
								<v-icon
									v-if="!pageLoading && !cols.fixed"
									class="draggable-action draggable-drag-icon"
									right
									color="blue"
									>mdi-drag</v-icon
								>
								<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
							</li>
						</template>
					</Draggable>
				</v-menu>
				<v-btn
					:disabled="pageLoading"
					color="blue darken-4"
					class="text-white"
					v-on:click="exportDialogNew = true"
					tile
					depressed
				>
					<v-icon dark left>mdi-database-export</v-icon> Export
				</v-btn>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Customer #, Name, Company Name, Display Name and Email Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="customer"
			delete-endpoint="contact/"
			detail-route="customer-detail"
			update-route="customer-update"
			v-on:reload:content="filterRows"
			delete-note="All transactions of this customer will also be deleted."
		></Table>
		<ExportDialog
			endpoint="customer/export?type=customer&current=1"
			title="Customers"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="customer/export?type=customer"
			title="Customers"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ExportDialogNew
			endpoint="customer/export?type=customer"
			title="Customers"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<ImportDialog
			endpoint="customer/import?type=customer"
			title="Customers"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Customers"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";

export default {
	name: "customer-listing",
	title: "Listing Customer",
	mixins: [ListingMixin],
	data() {
		return {
			pageTitle: "Customers",
			pageBreadcrumbs: [
				{ text: "Contacts", disabled: true },
				{ text: "Customer", disabled: true },
			],
			endpoint: "contact/customer",
		};
	},
};
</script>
